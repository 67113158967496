<template>
  <div class="page">
    <Navbar title="职业技能" :callback="back" type="SMP" />
    <div class="skill">
      <van-row class="title">
        <van-col span="6">关系</van-col>
        <van-col span="12">名称</van-col>
        <van-col span="6">操作</van-col>
      </van-row>
      <van-row class="record" v-for="family in familyList" :key="family">
        <van-col span="4">{{ family.relation }}</van-col>
        <van-col span="14" class="desc">{{ family.name }}</van-col>
        <van-col span="6"
          ><van-tag :color="COLOR" plain @click="updateFamily(family.seq)"
            >修改</van-tag
          >&nbsp;
          <van-tag type="danger" plain @click="deleteFamily(family.seq)"
            >删除</van-tag
          ></van-col
        >
      </van-row>
      <van-row class="no-record" v-if="familyList.length <= 0">
        <van-col span="24">
          <van-image
            width="103"
            height="103"
            :src="require('../../../../assets/images/home/no-record.png')"
          ></van-image
        ></van-col>
      </van-row>
    </div>
    <van-row class="btn">
      <van-col span="24">
        <van-button
          :color="COLOR"
          icon="plus"
          size="small"
          @click="createFamily()"
          >新增成员</van-button
        >
      </van-col>
    </van-row>
  </div>
</template>
<script>
import { Icon, Tag, Image } from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Image.name]: Image
  },
  data () {
    return {
      familyList: []
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.retrieveFamilyList()
  },
  methods: {
    async retrieveFamilyList () {
      var pd = { personCode: this.personCode, group: 'FMY', type: 'DTL' }
      this.familyList = []
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var family = { seq: element.seq, relation: content.relation, name: content.name }
          this.familyList.push(family)
        })
      }
    },
    createFamily () {
      this.$router.push({ path: '/mde/people/person/dtlFamilyEdit', query: { personCode: this.personCode, seq: this.familyList.length + 1 } })
    },
    updateFamily (seq) {
      this.$router.push({ path: '/mde/people/person/dtlFamilyEdit', query: { personCode: this.personCode, seq: seq } })
    },
    deleteFamily (seq) {
      this.$dialog.confirm({
        title: '删除确认',
        message: '确认删除职业技能吗?'
      })
        .then(() => {
          this.deleteFamilyExecute(seq)
        })
    },
    async deleteFamilyExecute (seq) {
      var pd = { key: 'PSN_FMY_ITEM', personCode: this.personCode, type: 'DTL', seq: seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/deletePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.retrieveFamilyList()
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '删除失败:' + res.msg
        })
      }
    },
    back () {
      var url = window.sessionStorage.getItem(this.SESSION_BACKURL)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.skill {
  margin: 5px;
  .title {
    font-size: 13px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
  .record {
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #eee;
    .desc {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
  .no-record {
    margin: 30px auto;
  }
}
.btn {
  margin-top: 30px;
}
</style>
